/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react'

import { debugAssert } from '@asta/lib'

import { ActionCreator, CombinedActions } from './createAction'

/**
 * Binds action creators to a dispatch function.
 *
 * @example
 * ```tsx
 * import React, { useReducer } from 'react'
 * import { bindCreatorsToDispatch, createAction, CombinedActions } from '@asta/shared-components'
 *
 * const actionCreators = {
 * 	increment: createAction('increment'),
 * 	decrement: createAction('decrement'),
 * }
 * type Action = CombinedActions<typeof actionCreators>
 *
 * const Counter = () => {
 * 	const [state, dispatch] = useReducer((state: number, action: Action) => {
 * 		switch (action.type) {
 * 			case 'increment':
 * 				return state + 1
 * 			case 'decrement':
 * 				return state - 1
 * 			default:
 * 				return state
 * 		}
 * 	}, 0)
 *
 * 	const actions = bindCreatorsToDispatch(dispatch, actionCreators)
 *
 * 	return (
 * 		<div>
 * 			<button onClick={() => actions.increment()}>+</button>
 * 		    <span>{state}</span>
 * 		    <button onClick={() => actions.decrement()}>-</button>
 * 		</div>
 * 	)
 * }
 * ```
 *
 * @param dispatch The dispatch function to bind the action creators to.
 * @param actionCreators The action creators to bind to the dispatch function.
 *
 * @template ACM The action creator map type containing your action creators
 * @template A The union type of all actions produced by the action creators.
 * You shouldn't need to specify this type; let type inference do its job.
 */
export const bindCreatorsToDispatch = <
	ACM extends Record<string, ActionCreator<any, any>>,
	A extends CombinedActions<ACM> = CombinedActions<ACM>,
>(
	dispatch: Dispatch<A>,
	actionCreators: ACM
): ACM => {
	const boundActionCreators = {} as ACM
	debugAssert(actionCreators, 'actionCreators must be defined')

	for (const key in actionCreators) {
		const actionCreator = actionCreators[key as keyof ACM]
		const bound = (...args: any[]) => {
			const action: A = (actionCreator as any)(...args)
			dispatch(action)
			return action
		}
		Object.defineProperty(bound, 'name', {
			value: `bound(${actionCreator.name})`,
			writable: false,
		})
		boundActionCreators[key as keyof ACM] = bound as any
	}

	return boundActionCreators
}
