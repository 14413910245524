import React from 'react'

import { FormControl, FormHelperText, TextField } from '@mui/material'

import { IssueState } from './IssueReporterReducer'
import { ConfigField, FormField } from './ReporterContext'

interface RenderTextFieldProps {
	field: ConfigField
	errors: { [key: string]: string }
	fieldData: FormField
	formData: IssueState
	onInputChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
}

const RenderTextField: React.FC<RenderTextFieldProps> = ({
	field,
	errors,
	fieldData,
	formData,
	onInputChange,
}) => (
	<FormControl
		fullWidth
		margin="normal"
		error={!!errors[field.name]}
		key={field.name}
	>
		<TextField
			name={field.name}
			label={field.name}
			value={formData[field.name] || ''}
			onChange={onInputChange}
			required={field.required || false}
			type={fieldData?.dataType || 'text'}
			placeholder={field?.placeholder}
		/>
		{errors[field.name] && (
			<FormHelperText>{errors[field.name]}</FormHelperText>
		)}
	</FormControl>
)

export default RenderTextField
