import React from 'react'

import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import { IssueState } from './IssueReporterReducer'
import { ConfigField, FormField, menuProps } from './ReporterContext'

interface RenderConfigurationFieldProps {
	field: ConfigField
	errors: { [key: string]: string }
	fieldData: FormField
	formData: IssueState
	onSelectChange: (event: SelectChangeEvent<string>) => void
}

const RenderConfigurationField: React.FC<RenderConfigurationFieldProps> = ({
	field,
	errors,
	fieldData,
	formData,
	onSelectChange,
}) => (
	<FormControl
		fullWidth
		margin="normal"
		error={!!errors[field.name]}
		key={field.id}
	>
		<InputLabel>{field.name}</InputLabel>
		<Select
			name={field.name}
			value={(formData[field.name] || '').toString()}
			onChange={onSelectChange}
			MenuProps={menuProps}
			required={field.required}
		>
			{fieldData?.configuration?.iterations?.map(iteration => (
				<MenuItem key={iteration.id} value={iteration.id}>
					{iteration.startDate}
				</MenuItem>
			))}
		</Select>
		{errors[field.name] && (
			<FormHelperText>{errors[field.name]}</FormHelperText>
		)}
	</FormControl>
)

export default RenderConfigurationField
