import { useReducer } from 'react'

import {
	bindCreatorsToDispatch,
	createAction,
	InferAction,
} from '@asta/react-component-library/src/state/index'
import { produce } from 'immer'

import {
	getIssue,
	getProjectFields,
	getProjectItemId,
	getProjects,
	IssueResponse,
} from './reporter-api-caller'
import { FormField, Issue, Project } from './ReporterContext'

export const templateDescription = `### Problem Description
<!--- state the action performed, the actual result, and the expected result --->
When ...
Then ...

### Context

* ASTA instance: 
* application / variant: 
* run template:
* run: 
* link:

### Steps to Reproduce
<!--- step, step... --->

### Screenshots
<!--- If applicable, add screenshots to help explain your problem. --->

### Additional Information`

const constructIssueDescription = (
	state: IssueState,
	appContext: {
		url: string
		screenshot: string
		environment: string
		date: string
		astaVersion: string
	},
	userContext: { browserVersion: string; username: string }
): string => {
	return `### Problem Description
  <!--- state the action performed, the actual result, and the expected result --->
  When ...
  Then ...
  
  ### Context
  
  * ASTA instance: ${appContext.environment}
  * application / variant: 
  * run template:
  * run: 
  * link:
  
  ### Steps to Reproduce
  <!--- step, step... --->
  
  ### Additional Information
  
  **App Context:**
  URL: ${appContext.url}
  ASTA Version: ${appContext.astaVersion}
  Date: ${appContext.date}
  
  **User Context:**
  Browser Version: ${userContext.browserVersion}
  Username: ${userContext.username}

  ### Screenshots
	`
}

export interface IssueState {
	title: string
	description: string
	screenshots: { url: string }[]
	projects: Project[]
	fields: FormField[]
	errors: { [key: string]: string }
	snackbarMessage: string
	snackbarSeverity: 'error' | 'success'
	snackbarOpen: boolean
	isUpdateMode: boolean
	projectItemId: string
	issueNumber: string
	loadedTitle: string
	loadedDescription: string
	[key: string]:
		| string
		| { url: string }[]
		| Project[]
		| FormField[]
		| { [key: string]: string }
		| boolean
}

export const actions = {
	setTitle: createAction<string, 'setTitle'>('setTitle'),
	setDescription: createAction<string, 'setDescription'>('setDescription'),
	setSeverity: createAction<string, 'setSeverity'>('setSeverity'),
	setScreenshot: createAction<string, 'setScreenshot'>('setScreenshot'),
	setField: createAction('setField', (name: string, value: string) => ({
		name,
		value,
	})),
	setIssue: createAction<Issue, 'setIssue'>('setIssue'),
	validateForm: createAction('validateForm'),
	formSubmissionSuccess: createAction('formSubmissionSuccess'),
	formSubmissionFailure: createAction<string, 'formSubmissionFailure'>(
		'formSubmissionFailure'
	),
	setSnackbar: createAction<
		{ message: string; severity: 'error' | 'success'; open: boolean },
		'setSnackbar'
	>('setSnackbar'),
	toggleUpdateMode: createAction('toggleUpdateMode'),
	resetErrors: createAction('resetErrors'),
	setProjects: createAction<Project[], 'setProjects'>('setProjects'),
	setProjectFields: createAction<FormField[], 'setProjectFields'>(
		'setProjectFields'
	),
	getState: createAction('getState'),
	setProjectItemId: createAction<string, 'setProjectItemId'>(
		'setProjectItemId'
	),
	setIssueNumber: createAction<string, 'setIssueNumber'>('setIssueNumber'),
	reset: createAction('reset'),
	setIssueContext: createAction<
		{
			appContext: {
				url: string
				screenshot: string
				environment: string
				date: string
				astaVersion: string
			}
			userContext: { browserVersion: string; username: string }
		},
		'setIssueContext'
	>('setIssueContext'),
	setFormState: createAction<IssueState, 'setFormState'>('setFormState'),
}

export type IssueAction = InferAction<(typeof actions)[keyof typeof actions]>

const reporterInitialState: IssueState = {
	title: '',
	description: templateDescription,
	screenshots: [],
	projects: [],
	fields: [],
	errors: {},
	snackbarMessage: '',
	snackbarSeverity: 'success',
	snackbarOpen: false,
	isUpdateMode: false,
	issueNumber: '',
	projectItemId: '',
	loadedTitle: '',
	loadedDescription: templateDescription,
}

const issueFormReducer = produce((state: IssueState, action: IssueAction) => {
	switch (action.type) {
		case actions.setTitle.type: {
			state.title = action.payload
			return
		}
		case actions.setDescription.type: {
			state.description = action.payload
			return
		}
		case actions.setScreenshot.type: {
			if (action.payload) {
				state.screenshots.push({ url: action.payload })
			}
			return
		}
		case actions.setField.type: {
			const {
				payload: { name, value },
			} = action
			state[name] = value
			return
		}
		case actions.setIssue.type: {
			const { title, description, screenshots } = action.payload
			state.title = title
			state.description = description
			state.screenshots = screenshots
			return
		}
		case actions.validateForm.type: {
			const newErrors: { [key: string]: string } = {}
			if (state.title == '') newErrors.title = 'Title is required'
			if (state.description == '')
				newErrors.description = 'Description is required'
			state.errors = newErrors
			return
		}
		case actions.formSubmissionSuccess.type: {
			state.snackbarMessage = 'Form submitted successfully'
			state.snackbarSeverity = 'success'
			return
		}
		case actions.formSubmissionFailure.type: {
			state.snackbarMessage = action.payload
			state.snackbarSeverity = 'error'
			return
		}
		case actions.setSnackbar.type: {
			const { message, severity, open } = action.payload
			state.snackbarMessage = message
			state.snackbarSeverity = severity
			state.snackbarOpen = open
			return
		}
		case actions.toggleUpdateMode.type: {
			if (state.isUpdateMode === false) {
				state.isUpdateMode = true
			} else {
				state.isUpdateMode = false
			}
			return
		}
		case actions.resetErrors.type: {
			state.errors = {}
			return
		}
		case actions.setProjects.type: {
			state.projects = action.payload
			return
		}
		case actions.setProjectFields.type: {
			state.fields = action.payload
			return
		}
		case actions.getState.type: {
			return state
		}
		case actions.setIssueNumber.type: {
			state.issueNumber = action.payload
			return
		}
		case actions.setProjectItemId.type: {
			state.projectItemId = action.payload
			return
		}
		case actions.reset.type: {
			state = reporterInitialState
			return state
		}
		case actions.setIssueContext.type: {
			const { appContext, userContext } = action.payload
			state.description = constructIssueDescription(
				state,
				appContext,
				userContext
			)
			return
		}
		case actions.setFormState.type: {
			state = action.payload
			return
		}
		default:
			return state
	}
})

export { issueFormReducer, reporterInitialState }

export const useIssueFormReducer = (): [IssueState, typeof actions] => {
	const [state, dispatch] = useReducer(issueFormReducer, reporterInitialState)
	const boundActions = bindCreatorsToDispatch(dispatch, actions)

	return [state, boundActions] as const
}

export const fetchProjects = async (): Promise<Project[]> => {
	const projects = await getProjects()
	return projects
}

export const fetchProjectFields = async (
	projectIndex: number
): Promise<FormField[]> => {
	const fields = await getProjectFields(projectIndex)
	return fields
}

export const loadIssueData = async (
	issueNumber: string
): Promise<{ loadedIssue: IssueResponse; projectItemId: string }> => {
	const loadedIssue = await getIssue(issueNumber)
	const projectItemId = await getProjectItemId(loadedIssue.node_id)
	return { loadedIssue, projectItemId }
}
