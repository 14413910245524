import { CSSProperties } from 'react'

export const commonStyle: CSSProperties = {
	padding: '10px',
	margin: '5px',
	border: '1px solid black',
	fontFamily: 'Arial, sans-serif',
	fontSize: '16px',
	color: 'black',
	zIndex: 2147483647,
}

export const commonText: CSSProperties = {
	fontFamily: 'Arial, sans-serif',
	color: 'black',
	fontSize: '16px',
	textAlign: 'center',
}

export const commonButton: CSSProperties = {
	padding: '5px 10px',
	border: '1px solid black',
	fontSize: '16px',
	borderRadius: '4px',
	backgroundColor: '#e7e7e7',
	textAlign: 'center',
	cursor: 'pointer',
}
