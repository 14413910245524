import { graphql, GraphQlQueryResponseData } from '@octokit/graphql'
import axios from 'axios'

import formConfig from '../../../public/formConfig.json'
import { FormField, Project } from './ReporterContext'

export type GithubConfig = {
	githubToken: string
	repoOwner: string
	repoName: string
}

export const getGithubConfig = async (): Promise<GithubConfig> => {
	if (!chrome?.storage?.sync) {
		// assume using api throw companion
		const config: GithubConfig = {
			githubToken: formConfig.githubToken,
			repoOwner: formConfig.repoOwner,
			repoName: formConfig.repoName,
		}
		return config
	} else {
		const config = (await chrome.storage.sync.get([
			'githubToken',
			'repoOwner',
			'repoName',
		])) as GithubConfig

		if (!config.githubToken || !config.repoOwner || !config.repoName) {
			throw new Error(
				'Missing GitHub configuration, please navigate to the extension options and set the GitHub token'
			)
		}
		return config
	}
}

export interface IssueResponse {
	id: number
	node_id: string
	title: string
	body: string
	state: string
}

export interface ProjectV2FieldValue {
	text?: string
	singleSelectOptionId?: string
	number?: number
	date?: string
	iterationId?: string
}

interface GetFieldsResponse extends GraphQlQueryResponseData {
	node: {
		fields: {
			nodes: FormField[]
		}
	}
}

interface GetProjectsResponse extends GraphQlQueryResponseData {
	repository: {
		projectsV2: {
			nodes: Project[]
		}
	}
}

// Return a list of projects
// Although right now we are just assuming that we have one project
export const getProjects = async (): Promise<Project[]> => {
	try {
		const { githubToken, repoOwner, repoName } = await getGithubConfig()
		const query = `
      {
        repository(owner: "${repoOwner}", name: "${repoName}") {
          projectsV2(first: 10) {
            nodes {
              id
              title
            }
          }
        }
      }
    `

		const response = await graphql<GetProjectsResponse>(query, {
			headers: {
				authorization: `token ${githubToken}`,
			},
		})

		return response.repository.projectsV2.nodes
	} catch (error) {
		// Extension context invalidated error is thrown when the extension is reloaded
		// This is a known issue with the extension and the user should refresh the page
		if (error == 'Error: Extension context invalidated.') {
			throw new Error(
				'Extension context invalidated. Please refresh the page.'
			)
		} else {
			console.error(
				'Error fetching GitHub projects, please check your GitHub config in the extension options: ',
				error
			)
			throw new Error(
				'Failed to fetch GitHub projects, please check your GitHub config in the extension options. Error Message: ' +
					error
			)
		}
	}
}

const introspectionQuery = `
  query {
    __schema {
      types {
        name
        kind
        fields {
          name
          type {
            name
            kind
            ofType {
              name
              kind
            }
          }
        }
      }
    }
  }
`

// Use this to get more information on the graphQL schema
export const runIntrospectionQuery =
	async (): Promise<GraphQlQueryResponseData> => {
		const { githubToken } = await getGithubConfig()
		const response = await graphql<GraphQlQueryResponseData>(
			introspectionQuery,
			{
				headers: {
					authorization: `token ${githubToken}`,
				},
			}
		)
		return response
	}

// Given a project index, return the fields of that project
// Although right now we are just assuming that we have one project so projectIndex is 0
// Return the GitHub project fields
export const getProjectFields = async (
	projectIndex: number
): Promise<FormField[]> => {
	try {
		const { githubToken } = await getGithubConfig()
		const projects = await getProjects()
		const projectId = projects[projectIndex].id // Get the project ID based on the index

		const query = `
      {
      node(id: "${projectId}") {
        ... on ProjectV2 {
          fields(first: 20) {
            nodes {
              ... on ProjectV2Field {
                id
                name
                dataType
              }
              ... on ProjectV2IterationField {
                id
                name
                configuration {
                  iterations {
                    startDate
                    id
                  }
                }
              }
              ... on ProjectV2SingleSelectField {
                id
                name
                options {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `

		const response = await graphql<GetFieldsResponse>(query, {
			headers: {
				authorization: `token ${githubToken}`,
			},
		})

		return response.node.fields.nodes
	} catch (error) {
		// Extension context invalidated error is thrown when the extension is reloaded
		// This is a known issue with the extension and the user should refresh the page
		if (error == 'Error: Extension context invalidated.') {
			throw new Error(
				'Extension context invalidated. Please refresh the page.'
			)
		} else {
			console.error(
				'Error fetching GitHub project fields, please check your GitHub config in the extension options: ',
				error
			)
			throw new Error(
				'Failed to fetch GitHub project fields, please check your GitHub config in the extension options. Error Message: ' +
					error
			)
		}
	}
}

export const getIssue = async (issueNumber: string): Promise<IssueResponse> => {
	try {
		const { githubToken, repoOwner, repoName } = await getGithubConfig()

		const response = await axios.get(
			`https://api.github.com/repos/${repoOwner}/${repoName}/issues/${issueNumber}`,
			{
				headers: {
					Authorization: `Bearer ${githubToken}`,
					'Content-Type': 'application/json',
				},
			}
		)

		return response.data
	} catch (error) {
		throw new Error('Failed to fetch GitHub issue')
	}
}

export const updateProjectFields = async (
	projectItemId: string,
	projectId: string,
	fieldUpdates: { fieldId: string; value: ProjectV2FieldValue }[]
): Promise<void> => {
	try {
		const { githubToken } = await getGithubConfig()

		for (const fieldUpdate of fieldUpdates) {
			if (fieldUpdate.value) {
				const mutation = `
          mutation($input: UpdateProjectV2ItemFieldValueInput!) {
            updateProjectV2ItemFieldValue(input: $input) {
              projectV2Item {
                id
              }
            }
          }
        `

				const variables = {
					input: {
						projectId: projectId,
						itemId: projectItemId,
						fieldId: fieldUpdate.fieldId,
						value: fieldUpdate.value,
					},
				}

				await graphql(mutation, {
					...variables,
					headers: {
						authorization: `token ${githubToken}`,
					},
				})
			}
		}
	} catch (error) {
		console.error('Error updating project fields:', error)
		throw new Error('Failed to update project fields')
	}
}

export const getProjectItemId = async (
	issueNodeId: string
): Promise<string> => {
	try {
		const { githubToken } = await getGithubConfig()

		const query = `
      query($issueNodeId: ID!) {
        node(id: $issueNodeId) {
          ... on Issue {
            projectItems(first: 10) {
              nodes {
                id
              }
            }
          }
        }
      }
    `

		const variables = {
			issueNodeId: issueNodeId,
		}

		const response = await graphql<{
			node: {
				projectItems: {
					nodes: {
						id: string
					}[]
				}
			}
		}>(query, {
			...variables,
			headers: {
				authorization: `token ${githubToken}`,
			},
		})

		const projectItem = response.node.projectItems.nodes[0]

		if (!projectItem) {
			throw new Error('Project item not found')
		}

		return projectItem.id
	} catch (error) {
		throw new Error(
			'Failed to fetch project item ID. Error Message: ' + error
		)
	}
}

export const postIssueToGitHub = async (
	title: string,
	description: string
): Promise<IssueResponse> => {
	try {
		const { githubToken, repoOwner, repoName } = await getGithubConfig()

		const labels = ['type: bug 🐛'] // Default label

		const response = await axios.post(
			`https://api.github.com/repos/${repoOwner}/${repoName}/issues`,
			{
				title: title,
				body: description,
				labels: labels,
			},
			{
				headers: {
					Authorization: `Bearer ${githubToken}`,
					'Content-Type': 'application/json',
				},
			}
		)

		return response.data
	} catch (error) {
		console.error('Error posting issue to GitHub:', error)
		throw new Error('Failed to post issue to GitHub')
	}
}

export const updateIssueOnGitHub = async (
	issueNumber: string,
	title: string,
	body: string
): Promise<IssueResponse> => {
	try {
		const { githubToken, repoOwner, repoName } = await getGithubConfig()

		const response = await axios.patch(
			`https://api.github.com/repos/${repoOwner}/${repoName}/issues/${issueNumber}`,
			{
				title,
				body,
			},
			{
				headers: {
					Authorization: `Bearer ${githubToken}`,
					'Content-Type': 'application/json',
				},
			}
		)

		return response.data
	} catch (error) {
		console.error('Error updating issue on GitHub:', error)
		throw new Error('Failed to update issue on GitHub')
	}
}
