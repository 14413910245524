import React, {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import { Point } from '@asta/lib'
import { useToggle } from '@asta/react-component-library'
import { Button, SelectChangeEvent, styled } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import html2canvas from 'html2canvas'

import packageContext from '../../../../companion/package.json'
import { useAstaAuth } from '../../../../companion/src/contexts/auth/useAuth'
import formConfig from '../../../public/formConfig.json'
import {
	actions,
	fetchProjectFields,
	fetchProjects,
	IssueState,
	loadIssueData,
	reporterInitialState,
	useIssueFormReducer,
} from './IssueReporterReducer'
import { getGithubConfig, GithubConfig } from './reporter-api-caller'

export type Issue = {
	id?: string
	title: string
	description: string
	screenshots: { url: string }[]
}

export type Position = {
	x: number
	y: number
}

export type FetchError = {
	message: string
	severity: 'error' | 'warning'
}

type AppContext = {
	url: string
	screenshot: string
	environment: string
	date: string
	astaVersion: string
}

type UserContext = {
	browserVersion: string
	username: string
}

export const ZERO: Point = { x: 0, y: 0 }
export const StartingPos: Point = { x: 0, y: 60 }
export const projectIndex = formConfig.projectIndex

// Used to prevent the portal from rendering
// Outside the root component
export const menuProps = {
	disablePortal: true,
}

const styledButton = (
	backgroundColor: string,
	hoverColor: string,
	fontSize: string,
	textColor: string
) =>
	styled(Button)(({ theme }) => ({
		backgroundColor: backgroundColor || 'white',
		color: textColor || 'black',
		border: `1px solid ${theme.palette.primary.main}`,
		fontSize,
		'&:hover': {
			backgroundColor: hoverColor || 'gray',
			color: '#fff',
		},
	}))

export const CloseButton = styledButton(
	'theme.palette.secondary.light',
	'red',
	'1.25rem',
	'theme.palette.primary.main'
)
export const ModeSwitchButton = styledButton(
	'theme.palette.secondary.dark',
	'theme.palette.secondary.light',
	'.75rem',
	'theme.palette.primary.main'
)
export const SubmitButton = styledButton(
	'theme.palette.primary.main',
	'theme.palette.primary.light',
	'1.5rem',
	'theme.palette.primary.main'
)

type ReporterContextType = {
	issue: Issue
	setIssue: React.Dispatch<React.SetStateAction<Issue>>
	pos: Position
	captureScreenshot: () => void
	captureScreenshotElement: (elementToScreenshot: HTMLElement) => void
	screenshotRef: React.RefObject<HTMLAnchorElement | null>
	selectedElements: HTMLElement[]
	setSelectedElements: React.Dispatch<React.SetStateAction<HTMLElement[]>>
	onInputChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
	onSelectChange: (event: SelectChangeEvent<string>) => void
	formData: IssueState
	issueFormActions: typeof actions
	onInputChangeIssueNumber: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
	onModeSwitch: () => void
	startIndexProjectFields: number
	endIndexProjectFields: number
	snackbarOpen: boolean
	openSnackbar: () => void
	closeSnackbar: () => void
	reporterVisible: boolean
	toggleReporter: () => void
	showReporter: () => void
	hideReporter: () => void
	issuePosition: Position
	fetchError: FetchError | null
	setFetchError: React.Dispatch<React.SetStateAction<FetchError | null>>
	retryQueries: () => void
	captureAppContext: () => Promise<{
		url: string
		screenshot: string
		environment: string
		date: string
		astaVersion: string
	}>
	captureUserContext: () => { browserVersion: string; username: string }
	appContext: AppContext | null
	userContext: UserContext | null
	handleTestCaptureContext: () => void
}

export const ReporterContext = createContext<ReporterContextType | undefined>(
	undefined
)

function getBrowserName(userAgent: string | string[]) {
	// The order matters here, and this may report false positives for unlisted browsers.

	if (userAgent.includes('Firefox')) {
		// "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
		return 'Mozilla Firefox'
	} else if (userAgent.includes('SamsungBrowser')) {
		// "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
		return 'Samsung Internet'
	} else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
		// "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
		return 'Opera'
	} else if (userAgent.includes('Edge')) {
		// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
		return 'Microsoft Edge (Legacy)'
	} else if (userAgent.includes('Edg')) {
		// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
		return 'Microsoft Edge (Chromium)'
	} else if (userAgent.includes('Chrome')) {
		// "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
		return 'Google Chrome or Chromium'
	} else if (userAgent.includes('Safari')) {
		// "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
		return 'Apple Safari'
	} else {
		return 'unknown'
	}
}

export type ContextProps = React.PropsWithChildren<object>

export const ReporterContextProvider: React.FC<ContextProps> = ({
	children,
}) => {
	const [selectEnabled, setSelectEnabled] = useState(false)
	const [pos, setPos] = useState<Position>(ZERO)
	const [offset, setOffset] = useState<Position>(ZERO)
	const screenshotRef = useRef<HTMLAnchorElement | null>(null)
	const [selectedElements, setSelectedElements] = useState<HTMLElement[]>([])
	const [issue, setIssue] = useState<Issue>(reporterInitialState)
	const [formData, issueFormActions] = useIssueFormReducer()
	const startIndexProjectFields = formData.fields.findIndex(
		field => field.name === formConfig.firstProjectFieldName
	)
	const endIndexProjectFields = formData.fields.length
	const [snackbarOpen, , openSnackbar, closeSnackbar] = useToggle()
	const [reporterVisible, toggleReporter, showReporter, hideReporter] =
		useToggle(false)
	const [issuePosition, _setIssuePosition] = useState(StartingPos)
	const [githubConfig, setGithubConfig] = useState<GithubConfig | null>(null)
	const [fetchError, setFetchError] = useState<FetchError | null>(null)
	const queryClient = useQueryClient()
	const [loadingIssueData, setLoadingIssueData] = useState(false)
	const { user } = useAstaAuth()
	const [appContext, setAppContext] = useState<AppContext | null>(null)
	const [userContext, setUserContext] = useState<UserContext | null>(null)

	useEffect(() => {
		;(async () => {
			const config = await getGithubConfig()
			setGithubConfig(config)
		})()
	}, [])

	const captureScreenshot = useCallback(async () => {
		const canvas = await html2canvas(document.body)
		const base64Image = canvas.toDataURL('image/jpeg')
		return base64Image
	}, [])

	const captureScreenshotElement = useCallback(
		async (elementToScreenshot: HTMLElement) => {
			const canvas = await html2canvas(elementToScreenshot)
			const base64Image = canvas.toDataURL('image/jpeg')
			return base64Image
		},
		[]
	)

	// const captureScreenshot = useCallback(async () => {
	// 	const canvas = await html2canvas(document.body)
	// 	const id = uuid()

	// 	canvas.toBlob(blob => {
	// 		if (blob) {
	// 			const base64Image = canvas.toDataURL('image/jpeg')
	// 			setIssue(prevIssue => ({
	// 				...prevIssue,
	// 				description: `${prevIssue.description}\n![Screenshot](${base64Image})`,
	// 			}))

	// 			if (screenshotRef.current) {
	// 				screenshotRef.current.href = base64Image.replace(
	// 					'image/jpeg',
	// 					'image/octet-stream'
	// 				)
	// 				screenshotRef.current.download = `${id}.jpg`
	// 				screenshotRef.current.click()
	// 			}
	// 		}
	// 	}, 'image/jpeg')
	// }, [])

	// const captureScreenshotElement = useCallback(
	// 	async (elementToScreenshot: HTMLElement) => {
	// 		const canvas = await html2canvas(elementToScreenshot)
	// 		const id = uuid()

	// 		canvas.toBlob(async blob => {
	// 			if (blob) {
	// 				const base64Image = canvas.toDataURL('image/jpeg')

	// 				setIssue(prevIssue => {
	// 					const screenshotSection =
	// 						'### Screenshots\n<!--- If applicable, add screenshots to help explain your problem. --->'
	// 					const newDescription = prevIssue.description.replace(
	// 						screenshotSection,
	// 						`${screenshotSection}\n![Screenshot](${base64Image})`
	// 					)
	// 					return {
	// 						...prevIssue,
	// 						Description: newDescription,
	// 					}
	// 				})

	// 				if (screenshotRef.current) {
	// 					screenshotRef.current.href = base64Image.replace(
	// 						'image/jpeg',
	// 						'image/octet-stream'
	// 					)
	// 					screenshotRef.current.download = `${id}.jpg`
	// 					screenshotRef.current.click()
	// 				}
	// 			}
	// 		}, 'image/jpeg')
	// 	},
	// 	[]
	// )

	const onInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const { name, value } = event.target
			issueFormActions.setField(name, value)
		},
		[issueFormActions]
	)

	const onInputChangeIssueNumber = useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const { value } = event.target
			issueFormActions.setIssueNumber(value)
			setLoadingIssueData(true)
		},
		[issueFormActions]
	)

	const onSelectChange = useCallback(
		(event: SelectChangeEvent<string>) => {
			const { name, value } = event.target
			issueFormActions.setField(name, value)
		},
		[issueFormActions]
	)

	const {
		data: projects,
		error: projectsError,
		isError: isProjectsError,
	} = useQuery({
		queryKey: ['projects'],
		queryFn: fetchProjects,
		enabled: !!githubConfig,
		retry: false, // Prevent infinite retry loops
	})

	const {
		data: fields,
		error: fieldsError,
		isError: isFieldsError,
	} = useQuery({
		queryKey: ['fields', formConfig.projectIndex],
		queryFn: () => fetchProjectFields(formConfig.projectIndex),
		enabled: !!projects && !!githubConfig, // Only fetch if projects are available
		retry: false, // Prevent infinite retry loops
	})

	const {
		data: issueData,
		error: issueError,
		isError: isIssueError,
	} = useQuery({
		queryKey: ['issueData', formData.issueNumber],
		queryFn: () => loadIssueData(formData.issueNumber),
		enabled: !!formData.issueNumber && !!githubConfig, // Only fetch if issueNumber is available
		retry: false, // Prevent infinite retry loops
	})

	useEffect(() => {
		if (projects && formData.projects.length === 0) {
			issueFormActions.setProjects(projects)
		}
	}, [projects, formData.projects.length, issueFormActions])

	useEffect(() => {
		if (fields && formData.fields.length === 0) {
			issueFormActions.setProjectFields(fields)
		}
	}, [fields, formData.fields.length, issueFormActions])

	useEffect(() => {
		if (issueData && formData.issueNumber && loadingIssueData) {
			issueFormActions.setField(
				'loadedTitle',
				issueData.loadedIssue.title
			)
			issueFormActions.setField(
				'loadedDescription',
				issueData.loadedIssue.body
			)
			issueFormActions.setProjectItemId(issueData.projectItemId)
			setLoadingIssueData(false)
		}
	}, [formData.issueNumber, issueData, issueFormActions, loadingIssueData])

	useEffect(() => {
		if (isProjectsError) {
			setFetchError({
				message: `Error fetching GitHub projects: ${projectsError.message}`,
				severity: 'error',
			})
		}
		if (isFieldsError) {
			setFetchError({
				message: `Error fetching GitHub project fields: ${fieldsError.message}`,
				severity: 'error',
			})
		}
		if (isIssueError) {
			// The user could be typing the issue number so we don't want to show an error
			setFetchError({
				message: `Could not load issue data (issue may have been deleted or incorrect number provided): ${issueError.message}`,
				severity: 'warning',
			})
		}
		// Clear the fetch error state if there are no errros
		if (!isProjectsError && !isFieldsError && !isIssueError) {
			setFetchError(null)
		}
	}, [
		isProjectsError,
		projectsError,
		isFieldsError,
		fieldsError,
		isIssueError,
		issueError,
	])

	const onModeSwitch = useCallback(() => {
		issueFormActions.toggleUpdateMode()
	}, [issueFormActions])

	const retryQueries = useCallback(() => {
		queryClient.invalidateQueries({ queryKey: ['projects'] })
		queryClient.invalidateQueries({
			queryKey: ['fields', formConfig.projectIndex],
		})
		queryClient.invalidateQueries({
			queryKey: ['issueData', formData.issueNumber],
		})
		setFetchError(null)
	}, [queryClient, formData.issueNumber])

	const captureAppContext = useCallback(async () => {
		const url = window.location.href
		const screenshot = await captureScreenshot()
		const environment = process.env.NODE_ENV?.toString() || 'unknown'
		const date = new Date().toLocaleString()
		const astaVersion = packageContext.version
		return {
			url,
			screenshot,
			environment,
			date,
			astaVersion,
		}
	}, [captureScreenshot])

	const captureUserContext = useCallback(() => {
		const browserVersion = getBrowserName(navigator.userAgent)
		const username = user?.email || 'unknown'
		return { browserVersion, username }
	}, [user?.email])

	const handleTestCaptureContext = useCallback(async () => {
		const appCtx = await captureAppContext()
		const userCtx = captureUserContext()
		setAppContext(appCtx)
		setUserContext(userCtx)
		issueFormActions.setScreenshot(appCtx.screenshot)
		issueFormActions.setIssueContext({
			appContext: appCtx,
			userContext: userCtx,
		})
	}, [captureAppContext, captureUserContext, issueFormActions])

	const contextValue = useMemo(
		() => ({
			issue,
			setIssue,
			pos,
			setPos,
			selectEnabled,
			setSelectEnabled,
			offset,
			setOffset,
			captureScreenshot,
			captureScreenshotElement,
			screenshotRef,
			selectedElements,
			setSelectedElements,
			onInputChange,
			onSelectChange,
			formData,
			issueFormActions,
			onInputChangeIssueNumber,
			onModeSwitch,
			startIndexProjectFields,
			endIndexProjectFields,
			snackbarOpen,
			openSnackbar,
			closeSnackbar,
			reporterVisible,
			showReporter,
			toggleReporter,
			hideReporter,
			issuePosition,
			fetchError,
			setFetchError,
			retryQueries,
			captureAppContext,
			captureUserContext,
			appContext,
			userContext,
			handleTestCaptureContext,
		}),
		[
			issue,
			pos,
			selectEnabled,
			offset,
			captureScreenshot,
			captureScreenshotElement,
			selectedElements,
			onInputChange,
			onSelectChange,
			formData,
			issueFormActions,
			onInputChangeIssueNumber,
			onModeSwitch,
			startIndexProjectFields,
			endIndexProjectFields,
			snackbarOpen,
			openSnackbar,
			closeSnackbar,
			reporterVisible,
			showReporter,
			toggleReporter,
			hideReporter,
			issuePosition,
			fetchError,
			retryQueries,
			captureAppContext,
			captureUserContext,
			appContext,
			userContext,
			handleTestCaptureContext,
		]
	)

	return (
		<ReporterContext.Provider value={contextValue}>
			{children}
		</ReporterContext.Provider>
	)
}

export interface Project {
	id: string
	title: string
}

export interface FieldOption {
	id: string
	name: string
}

export interface Iteration {
	id: string
	startDate: string
}

export interface ConfigField {
	id: number
	name: string
	type: string
	placeholder?: string
	required: boolean
}

// This is the type of the field that we get from the GitHub API
export interface FormField {
	id: string
	name: string
	dataType?: string
	type: string
	placeholder?: string
	options?: FieldOption[]
	required?: boolean
	text?: string
	configuration?: {
		iterations?: Iteration[]
	}
}
