import {
	FC,
	PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

import { Point } from '@asta/lib'
import { Button, ButtonOwnProps, styled } from '@mui/material'

type ForwardedProps = Pick<
	React.ComponentProps<'button'>,
	'id' | 'title' | 'className'
> &
	Pick<ButtonOwnProps, 'sx'>

interface DraggableButtonProps extends ForwardedProps {
	onClick: () => void
	startingPosition: Point
}

const ZERO: Point = { x: 0, y: 0 }
export const DraggableButton: FC<PropsWithChildren<DraggableButtonProps>> = ({
	sx,
	id,
	onClick,
	title,
	startingPosition,
	className,
	children,
}) => {
	const [pos, setPos] = useState(startingPosition)
	const dragging = useRef(false)
	const dragPosition = useRef<Point>(ZERO)
	const startPos = useRef<Point>(ZERO)

	const onMouseDown = useCallback(
		(e: React.MouseEvent) => {
			dragging.current = true
			startPos.current = {
				x: window.innerWidth - e.clientX,
				y: window.innerHeight - e.clientY,
			}
			dragPosition.current = {
				x: window.innerWidth - e.clientX - pos.x,
				y: window.innerHeight - e.clientY - pos.y,
			}
		},
		[pos.x, pos.y]
	)

	const onMouseMove = useCallback((e: MouseEvent) => {
		if (dragging.current) {
			setPos({
				x: window.innerWidth - e.clientX - dragPosition.current.x,
				y: window.innerHeight - e.clientY - dragPosition.current.y,
			})
		}
	}, [])

	const onMouseUp = useCallback(
		(e: MouseEvent) => {
			dragging.current = false
			const moved =
				e.clientX !== window.innerWidth - startPos.current.x ||
				e.clientY !== window.innerHeight - startPos.current.y
			if (!moved) onClick()
		},
		[onClick]
	)

	useEffect(() => {
		window.addEventListener('mousemove', onMouseMove)
		window.addEventListener('mouseup', onMouseUp)

		return () => {
			window.removeEventListener('mousemove', onMouseMove)
			window.removeEventListener('mouseup', onMouseUp)
		}
	}, [onMouseMove, onMouseUp])

	const style = useMemo(
		() => ({
			right: pos.x,
			bottom: pos.y,
		}),
		[pos]
	)
	return (
		<FixedButton
			sx={sx}
			style={style}
			className={className}
			id={id}
			onMouseDown={onMouseDown}
			title={title}
		>
			{children}
		</FixedButton>
	)
}

const FixedButton = styled(Button)`
	position: fixed;
`
