import { toError } from '@asta/lib'

// Used to retry a function a few times
// Only used to attempt to get the project item id
// This is because sometimes the project item id is not immediately available
export const retry = async <T,>(
	fn: () => Promise<T>,
	retries: number,
	delay: number
): Promise<T> => {
	let lastError: Error | unknown
	for (let i = 0; i < retries; i++) {
		try {
			const result = await fn()
			if (result) {
				return result
			}
		} catch (error) {
			lastError = toError(error)
			await new Promise(resolve => setTimeout(resolve, delay))
		}
	}
	lastError ??= new Error(
		`Failed to get project item id after ${retries} retries`
	)
	throw lastError
}
