import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
	Alert,
	Box,
	FormControl,
	SelectChangeEvent,
	SnackbarOrigin,
	styled,
	TextField,
} from '@mui/material'

import formConfig from '../../../public/formConfig.json'
import RenderConfigurationField from './ConfigurationField'
import { onSubmit } from './formActions'
import { IssueState } from './IssueReporterReducer'
import {
	ConfigField,
	FormField,
	ModeSwitchButton,
	ReporterContext,
	SubmitButton,
} from './ReporterContext'
import RenderSelectField from './SelectField'
import RenderTextArea from './TextArea'
import RenderTextField from './TextField'

const FormBox = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: '10px',
})

const divStyle = { marginBottom: '10px' }
export const fullWidth = { width: '100%' }
export const snackBarPosition: SnackbarOrigin = {
	vertical: 'bottom',
	horizontal: 'center',
}

const renderConfigField = (
	field: ConfigField,
	fieldData: FormField,
	formData: IssueState,
	onInputChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void,
	onSelectChange: (event: SelectChangeEvent<string>) => void
) => {
	switch (field.type) {
		case 'textarea':
			// Used for description field
			return (
				<RenderTextArea
					field={field}
					errors={formData.errors}
					fieldData={fieldData}
					formData={formData}
					onInputChange={onInputChange}
				/>
			)
		case 'select':
			return (
				<RenderSelectField
					field={field}
					errors={formData.errors}
					fieldData={fieldData}
					formData={formData}
					onSelectChange={onSelectChange}
				/>
			)
		case 'configuration':
			return (
				<RenderConfigurationField
					field={field}
					errors={formData.errors}
					fieldData={fieldData}
					formData={formData}
					onSelectChange={onSelectChange}
				/>
			)
		// Default to be a simple text field
		default:
			return (
				<RenderTextField
					field={field}
					errors={formData.errors}
					fieldData={fieldData}
					formData={formData}
					onInputChange={onInputChange}
				/>
			)
	}
}

interface FormConfig {
	firstProjectFieldIndex: number
	fields: ConfigField[]
}

interface FormGeneratorProps {
	hideDiv: () => void
	isCompanion: boolean
}

const inputProps = {
	readOnly: true,
}

// FormGenerator component
// This component is responsible for rendering the form
// Dynamically gets the fields from github and from the formConfig.json
// and handling the form submission
export const FormGenerator: React.FC<FormGeneratorProps> = ({
	hideDiv,
	isCompanion,
}) => {
	const context = useContext(ReporterContext)
	if (!context) {
		throw new Error('ReporterContext not found')
	}
	const {
		onSelectChange,
		onInputChange,
		onInputChangeIssueNumber,
		formData,
		issueFormActions,
		onModeSwitch,
		startIndexProjectFields,
		endIndexProjectFields,
		openSnackbar,
		setFetchError,
		showReporter,
	} = context
	const [dynamicFormConfig, setDynamicFormConfig] =
		useState<FormConfig | null>(null)

	useEffect(() => {
		if (!chrome?.storage?.sync) {
			// Assume the user is using the companion
			setDynamicFormConfig(formConfig)
		} else {
			try {
				chrome.storage.sync.get(['formConfigFields'], config => {
					if (config.formConfigFields) {
						setDynamicFormConfig(
							config.formConfigFields as FormConfig
						)
					}
				})
			} catch (error) {
				if (error == 'Error: Extension context invalidated.') {
					setFetchError({
						message:
							'Extension context invalidated. Please refresh the page.',
						severity: 'error',
					})
				} else {
					console.error('Error fetching form config fields', error)
					setFetchError({
						message: 'Error fetching form config fields',
						severity: 'error',
					})
				}
			}
		}
	}, [setFetchError])

	const loadedFields = () => {
		return (
			<>
				<FormControl fullWidth margin="normal">
					<TextField
						label="Issue Number"
						value={formData.issueNumber}
						onChange={onInputChangeIssueNumber}
					/>
				</FormControl>

				<FormControl fullWidth margin="normal">
					<TextField
						name="loadedTitle"
						label="Loaded Issue Title"
						value={formData.loadedTitle}
						onChange={onInputChange}
						required
					/>
				</FormControl>
				<FormControl fullWidth margin="normal">
					<TextField
						name="loadedDescription"
						label="Loaded Issue Description"
						value={formData.loadedDescription}
						onChange={onInputChange}
						required
						multiline
						rows={20}
					/>
				</FormControl>
			</>
		)
	}

	const memoizedOnSubmit = useCallback(
		(e: React.FormEvent) => {
			onSubmit(
				e,
				formData,
				startIndexProjectFields,
				endIndexProjectFields,
				openSnackbar,
				issueFormActions,
				hideDiv,
				showReporter
			)
		},
		[
			formData,
			startIndexProjectFields,
			endIndexProjectFields,
			openSnackbar,
			issueFormActions,
			hideDiv,
			showReporter,
		]
	)

	if (!dynamicFormConfig) {
		return (
			<Box>
				<Alert severity="error">
					Missing GitHub Config, please navigate to the extension
					options and set the GitHub token
				</Alert>
			</Box>
		)
	}

	const companionForm = (isUpdatemode: boolean) => {
		const companionFields = dynamicFormConfig.fields.filter(field =>
			[
				'title',
				'description',
				'Value/Impact',
				'Requested By',
				'Type',
			].includes(field.name)
		)
		if (isUpdatemode) {
			return companionFields
				.slice(2, formData.fields.length - 1)
				.map(field => (
					<div key={field.name} style={divStyle}>
						{renderConfigField(
							field,
							formData.fields[field.id],
							formData,
							onInputChange,
							onSelectChange
						)}
					</div>
				))
		} else {
			issueFormActions.setField('Requested By', 'Customer')
			if (!formData['Type']) {
				issueFormActions.setField('Type', 'Bug')
			}
			const customOptions = ['Epic', 'Feature', 'Improvement', 'Bug']
			return companionFields.map(field => (
				<div key={field.name} style={divStyle}>
					{field.name === 'Requested By' ? (
						<FormControl fullWidth margin="normal">
							<TextField
								name={field.name}
								label={field.name}
								value="Customer"
								onChange={onInputChange}
								required
								InputProps={inputProps}
							/>
						</FormControl>
					) : field.name === 'Type' ? (
						<RenderSelectField
							field={field}
							errors={formData.errors}
							fieldData={formData.fields[field.id]}
							formData={formData}
							onSelectChange={onSelectChange}
							defaultValue="Bug"
							customOptions={customOptions}
						/>
					) : (
						renderConfigField(
							field,
							formData.fields[field.id],
							formData,
							onInputChange,
							onSelectChange
						)
					)}
				</div>
			))
		}
	}

	return (
		<form onSubmit={memoizedOnSubmit}>
			<ModeSwitchButton onClick={onModeSwitch} variant="contained">
				{formData.isUpdateMode ? 'Post New Issue' : 'Update Issue'}
			</ModeSwitchButton>
			{formData.isUpdateMode && loadedFields()}
			{!formData.isUpdateMode && isCompanion && companionForm(false)}
			{!formData.isUpdateMode &&
				!isCompanion &&
				dynamicFormConfig.fields.map(field => (
					<div key={field.name} style={divStyle}>
						{renderConfigField(
							field,
							formData.fields[field.id],
							formData,
							onInputChange,
							onSelectChange
						)}
					</div>
				))}
			{formData.isUpdateMode && isCompanion && companionForm(true)}
			{formData.isUpdateMode &&
				!isCompanion &&
				dynamicFormConfig.fields
					.slice(
						dynamicFormConfig.firstProjectFieldIndex,
						formData.fields.length - 1
					)
					.map(field => (
						<div key={field.name} style={divStyle}>
							{renderConfigField(
								field,
								formData.fields[field.id],
								formData,
								onInputChange,
								onSelectChange
							)}
						</div>
					))}
			<FormBox>
				<SubmitButton type="submit" variant="contained" color="primary">
					Submit
				</SubmitButton>
			</FormBox>
		</form>
	)
}
