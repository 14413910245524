import React from 'react'

import { ErrorBoundary } from '@asta/react-component-library'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { ReporterContextProvider } from './IssueWidget/ReporterContext'
import { IssueWidget } from './Widget'

interface AppProps {
	isCompanion: boolean
}

const queryClient = new QueryClient()

export const ReporterApp: React.FC<AppProps> = ({ isCompanion }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<ReporterContextProvider>
				<ErrorBoundary>
					<IssueWidget isCompanion={isCompanion} />
				</ErrorBoundary>
			</ReporterContextProvider>
		</QueryClientProvider>
	)
}
