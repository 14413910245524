import { Action, PayloadAction } from './action'

export interface BaseActionCreator<T> {
	type: T
}
export interface SimpleActionCreator<T extends string>
	extends BaseActionCreator<T> {
	(): Action<T>
}
export interface PayloadActionCreator<T extends string, P>
	extends BaseActionCreator<T> {
	(payload: P): PayloadAction<P, T>
}

export interface PayloadActionCreatorWithPrepare<
	T extends string,
	F extends (...args: any[]) => any,
> extends BaseActionCreator<T> {
	(...args: Parameters<F>): PayloadAction<ReturnType<F>, T>
}

export type ActionCreator<T extends string, P = undefined> = P extends undefined
	? SimpleActionCreator<T>
	: P extends (...args: any[]) => any
		? PayloadActionCreatorWithPrepare<T, P>
		: PayloadActionCreator<T, P>

export type ActionCreatorMap<T extends string = string> = Record<
	T,
	ActionCreator<any, any>
>

export type InferAction<AC extends (...args: any) => any> =
	ReturnType<AC> extends Action<any> ? ReturnType<AC> : never

/**
 * Extracts the {@link Action} type from an {@link ActionCreator}
 */
export type ActionCreatorAction<AC extends ActionCreator<any, any>> =
	AC extends PayloadActionCreatorWithPrepare<infer TT, infer PP>
		? PayloadAction<ReturnType<PP>, TT>
		: AC extends SimpleActionCreator<infer TT>
			? Action<TT>
			: AC extends PayloadActionCreator<infer TT, infer PP>
				? PayloadAction<PP, TT>
				: never

/**
 * Gets a union type of all {@link Action}s produced by {@link ActionCreators}
 * in an action creator map.
 */
export type CombinedActions<
	ACM extends Record<string, ActionCreator<any, any>>,
> = {
	[K in keyof ACM]: ActionCreatorAction<ACM[K]>
}[keyof ACM]

export function createAction<
	const P = undefined,
	const T extends string = string,
>(type: T): ActionCreator<T, P>
export function createAction<
	T extends string,
	F extends (...args: any[]) => any,
>(type: T, prepare: F): PayloadActionCreatorWithPrepare<T, F>
export function createAction<T extends string, P>(
	type: T,
	prepare?: (...payloadOrArgs: any[]) => P
): any {
	const creator = prepare
		? (...args: any[]) => ({ type, payload: prepare(...args) })
		: (payload: P) => (payload ? { type, payload } : { type })

	;(creator as ActionCreator<T, P>).type = type
	return creator as ActionCreator<T, P>
}
