import React from 'react'

import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import { IssueState } from './IssueReporterReducer'
import { ConfigField, FormField, menuProps } from './ReporterContext'

interface RenderSelectFieldProps {
	field: ConfigField
	errors: { [key: string]: string }
	fieldData: FormField
	formData: IssueState
	onSelectChange: (event: SelectChangeEvent<string>) => void
	defaultValue?: string
	customOptions?: string[]
}

const RenderSelectField: React.FC<RenderSelectFieldProps> = ({
	field,
	errors,
	fieldData,
	formData,
	onSelectChange,
	defaultValue,
	customOptions,
}) => {
	return (
		<FormControl
			fullWidth
			margin="normal"
			error={!!errors[field.name]}
			key={field.id}
		>
			<InputLabel>{field.name}</InputLabel>
			<Select
				name={field.name}
				value={(formData[field.name] || defaultValue || '').toString()}
				onChange={onSelectChange}
				MenuProps={menuProps}
				required={field.required}
				defaultValue={defaultValue || ''}
			>
				{fieldData?.options &&
					customOptions &&
					fieldData.options.length === customOptions.length && // Ensure both arrays have the same length
					fieldData.options.map((option, index) => (
						<MenuItem key={option.id} value={option.name}>
							{customOptions[index]}
						</MenuItem>
					))}

				{fieldData?.options &&
					!customOptions &&
					fieldData?.options.map(option => (
						<MenuItem key={option.id} value={option.name}>
							{option.name}
						</MenuItem>
					))}
			</Select>
			{errors[field.name] && (
				<FormHelperText>{errors[field.name]}</FormHelperText>
			)}
		</FormControl>
	)
}
export default RenderSelectField
