import React from 'react'

import { FormControl, FormHelperText, TextField } from '@mui/material'

import { IssueState, templateDescription } from './IssueReporterReducer'
import { ConfigField, FormField } from './ReporterContext'

interface RenderTextAreaProps {
	field: ConfigField
	errors: { [key: string]: string }
	fieldData: FormField
	formData: IssueState
	onInputChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
}

const RenderTextArea: React.FC<RenderTextAreaProps> = ({
	field,
	errors,
	formData,
	onInputChange,
}) => (
	<FormControl
		fullWidth
		margin="normal"
		error={!!errors[field.name]}
		key={field.name}
	>
		<TextField
			name={field.name}
			label={field.name}
			placeholder={field?.placeholder}
			required={field.required}
			value={formData[field.name] || templateDescription}
			onChange={onInputChange}
			multiline
			rows={20}
		/>
		{errors[field.name] && (
			<FormHelperText>{errors[field.name]}</FormHelperText>
		)}
	</FormControl>
)

export default RenderTextArea
