import React from 'react'

// material-ui
import type { LinearProgressProps } from '@mui/material/LinearProgress'
import LinearProgress from '@mui/material/LinearProgress'
import { Box } from '@mui/system'

// ==============================|| Loader ||============================== //

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => (
	<Box
		sx={{
			position: 'fixed',
			top: 0,
			left: 0,
			zIndex: 2001,
			width: '100%',
			'& > * + *': {
				marginTop: 2,
			},
		}}
	>
		<LinearProgress color="primary" />
	</Box>
)

export default Loader
